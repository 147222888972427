//ProductHierarchy Thunks

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProductHierarchyApi } from '../../api/ProductHierarchyApi';
import { EActivity } from '../../data/Constants';

//To get product hierarchy paginated data
export const getProductHierarchy = createAsyncThunk( 'productHierarchy/getProductHierarchy', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return ProductHierarchyApi.getProductHierarchy( action );
} )
  
//Compares the product hierarchy and returns the differentiated data
export const compareProductHierarchy = createAsyncThunk( 'productHierarchy/compareProductHierarchy', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return ProductHierarchyApi.compareProductHierarchy( action );
} )
  
//Publishes the Product heirarchy
export const publishProductHierarchy = createAsyncThunk( 'productHierarchy/publishProductHierarchy', async ( action:{ token: string, activity: EActivity } )=>{
  return ProductHierarchyApi.publishProductHierarchy( action );
} )
  
  