import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { EApiType, EDataTableType, EModuleType, TableHeaders } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { ErrorMessage } from '../../ErrorMessage';
import { getAllProperties, getFeaturePropertyAssociations } from '../../../store/states/PropertySlice';
import { IFeaturePropertyAssociation } from '../../../../types';
import { setColumnsData } from '../../../services/DataHelperFunctions';

export const FeaturePropertyAssociation = ( props: IFeaturePropertyAssociation ) => {
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )
  const propertyList = useSelector( ( state:AppState )=> state.dataMaintainance.propertyList )

  const [columns,setColumns] = useState( [] )

  useEffect( ()=>{
    if( propertyList.length === 0 ) {
      dispatch( getAllProperties( {token: props.token} ) );
    }
  },[] )

  useEffect( ()=>{
    setColumnsData( propertyList,setColumns,TableHeaders[EDataTableType.FeaturePropertyAssociation].main );
  },[propertyList] )


  if( error?.code && error.type === EApiType.FeaturePropertyAssociationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.FeaturePropertyAssociation }
      getDataList = { getFeaturePropertyAssociations }
      moduleType = { EModuleType.Datamaintainance }
      productId={ props.productId }
      columns={ columns }
    /></>
}