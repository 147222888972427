import React from 'react';
import { useTranslation } from 'react-i18next';
import { EApiType, EDataTableType, EModuleType } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { ErrorMessage } from '../../ErrorMessage';
import { getNotifications } from '../../../store/states/NotificationSlice';
import { useSelector } from 'react-redux';
import { IManagementProps } from '../../../../types';
import { AppState } from '../../../store/AppStore';

export const NotificationManagement = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const error = useSelector( ( state:AppState )=> state.error )

  if( error.code && error.type === EApiType.NotificationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <><h3 className="mainHeader"> {t( 'labels.notificationManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Ace }
      getDataList = { getNotifications }
      moduleType = { EModuleType.Notifications }
    /></>
}