import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppDispatch, AppState, AppStore } from '../../../store';
import { EApiType, EDataTableType, EModuleType } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { ErrorMessage } from '../../ErrorMessage';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { IManagementProps } from '../../../../types';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages, deleteLanguage } from '../../../store/states/LanguageSlice';


export const LanguageManagement = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )

  const deleteItems = ( req )=>{
    const languages = AppStore.getState().dataMaintainance.languages;
    dispatch( deleteLanguage( {token: props.token, request: req } ) ).then( res=>{
      const delKeys = joinSelectedKeys( req )
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.languages', { length:req.length, list:delKeys } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getLanguages( { token: props.token, page: languages.currentPage, limit: languages.recordsPerPage, searchCode: languages.searchKey } ) );
      }else{
        const message = t( 'messages.fail.delete.languages', { length:req.length, list: delKeys} );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }


  if( error.code && error.type === EApiType.LanguageKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <><h3 className="mainHeader"> {t( 'labels.languageManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Languages }
      getDataList = { getLanguages }
      moduleType = { EModuleType.Datamaintainance }
      deleteItems={ deleteItems }
    /></>
}