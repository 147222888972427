import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState, AppStore } from '../../../store';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage'; 
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { getRoles, deleteRoles } from '../../../store/states/RoleSlice';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';


export const RoleManagement = ( props:IManagementProps ) => {
  
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )

  const deleteItems = ( req )=>{
    const roles = AppStore.getState().administration.roles;
    dispatch( deleteRoles( {token: props.token, request: req} ) ).then( res=>{
      const delKeys = joinSelectedKeys( req )
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.roles', { length:req.length, list: delKeys } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getRoles( {token: props.token, page: roles.currentPage, limit: roles.recordsPerPage, searchCode: roles.searchKey } ) );
      }else{
        const message = t( 'messages.fail.delete.roles', { length:req.length, list: delKeys} );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }

  if( error.code && error.type === EApiType.RoleKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.roleManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Roles }
      getDataList = { getRoles }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}