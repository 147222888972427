import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../../data/Constants';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { getLanguageAssociation } from '../../../store/states/LanguageSlice';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/AppStore';

export const LanguageAssociation = ( props:IManagementProps ) => {
  const {t} = useTranslation();

  const error = useSelector( ( state:AppState )=> state.error )

  if( error?.code && error.type === EApiType.LanguageAssociationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.languageAssociation' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.LanguageAssociation }
      getDataList = { getLanguageAssociation }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}