import { configureStore } from '@reduxjs/toolkit'
import notificationReducer from './states/NotificationSlice';
import applicationSettingsReducer from '../store/states/ApplicationSettingsSlice';
import administrationReducer from '../store/states/AdministrationSlice';
import userReducer from './states/UserSlice';
import dialogReducer from './states/DialogSlice';
import alertMessageReducer from './states/AlertMessageSlice';
import errorReducer from './states/ErrorSlice';
import roleReducer from './states/RoleSlice';
import apiKeyReducer from './states/ApiKeySlice';
import featureFlagReducer from './states/FeatureFlagSlice';
import dataMaintainanceReducer from './states/DataMaintenanceSlice';
import busyReducer from './states/BusySlice';
import technicalInfoReducer from './states/TechnicalInfoSlice';

export const AppStore = configureStore (
  {
    reducer : {
      busy: busyReducer,
      error: errorReducer,
      alertMessage: alertMessageReducer,
      dialogData: dialogReducer,
      applicationSettings: applicationSettingsReducer,
      administration: administrationReducer,
      user: userReducer,
      roles: roleReducer,
      apiKeys: apiKeyReducer,
      dataMaintainance: dataMaintainanceReducer,
      featureFlags: featureFlagReducer,
      notifications: notificationReducer,
      technicalInfo: technicalInfoReducer
    },
    middleware:( getDefaultMiddleware ) => {
      return getDefaultMiddleware( {
        serializableCheck: false
      } )
    }} )

export type AppDispatch = typeof AppStore.dispatch

export type AppState = ReturnType<typeof AppStore.getState>;