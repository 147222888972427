import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle, Grid, RadioGroup, Radio } from '@mui/material';
import { AppDispatch, AppState, AppStore } from '../../../store';
import { EActionType, EAction, RegExPatterns, ELovValues } from '../../../data/Constants';
import { IManipulationProps, IError, IEnumOption, IApplicationManipulation } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { resetAlertMessage, setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { getApplications, updateApplication, createApplication, getApplicationDetails } from '../../../store/states/ApplicationSlice';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetError } from '../../../store/states/ErrorSlice';
import { getEnumOptions, resetAllErrorInfo } from '../../../services/DataHelperFunctions';

//To call create and update apis
function callCreateOrUpdateApi( manipulationType: string, props: IManipulationProps, formData: IApplicationManipulation, t: ( message: string ) => string, dispatch: AppDispatch ) {
  if ( manipulationType === EActionType.Edit ) {
    dispatch( updateApplication( { token: props.token, request: { ...formData, name: formData.name.trimEnd(), description: formData.description.trim() } } ) ).then( res => {
      handleApiResponse( res.payload, props, t( 'messages.fail.update' ), t( 'messages.success.update' ), dispatch );
    } )
  } else {
    dispatch( createApplication( { token: props.token, request: { ...formData, name: formData.name.trimEnd(), description: formData.description.trim() } } ) ).then( res => {
      handleApiResponse( res.payload, props, t( 'messages.fail.create' ), t( 'messages.success.create' ), dispatch );
    } )
  }
}

function handleApiResponse( res, props: IManipulationProps, failMessage: string, successMessage: string, dispatch: AppDispatch ) {
  const applications = AppStore.getState().administration.applications;
  if ( res?.data ) {
    dispatch( getApplications( { token: props.token, page: applications.currentPage, limit: applications.recordsPerPage, searchCode: applications.searchKey } ) )
    dispatch( setAlertMessage( { show: true, message: successMessage, type: 'success' } ) )
    dispatch( resetDialogMessage() );
  } else {
    const message = res?.error?.message ? res.error.message : failMessage;
    dispatch( setAlertMessage( { show: true, message: message, type: 'error' } ) )

  }
}

const loadFormData = async ( props: { token: string, id: string }, setInitialApplicationData: { ( value: React.SetStateAction<IApplicationManipulation> ): void }, setFormData: { ( value: React.SetStateAction<IApplicationManipulation> ): void }, dispatch: AppDispatch ) => {
  let applicationDetails = await dispatch( getApplicationDetails( { token: props.token, request: [props.id] } ) ).unwrap();

  if ( !applicationDetails || !applicationDetails.data ) {
    return;
  }

  applicationDetails = applicationDetails.data[0];
  setInitialApplicationData( applicationDetails );
  setFormData( applicationDetails );
}

const handleError = ( error: IError, setDisplayAlertMessage: { ( value: React.SetStateAction<boolean> ): void } ) => {
  if ( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }
}

const handleChange = ( event, setFormData: { ( value: React.SetStateAction<IApplicationManipulation> ): void } ) => {
  const name = event.target.name;
  const value = name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( { ...values, [name]: value } ) )
}

//Set the views to the state
const handleChangeView = ( event: React.ChangeEvent<HTMLInputElement>, setFormData: { ( value: React.SetStateAction<IApplicationManipulation> ): void }, formData: IApplicationManipulation ) => {
  const tempFormData = JSON.parse( JSON.stringify( formData ) )
  if ( event.target.checked ) {
    tempFormData.views.push( event.target.value )
  } else {
    tempFormData.views.splice( tempFormData.views.findIndex( ( view: string ) => view === event.target.value ), 1 )
  }
  setFormData( tempFormData )
}

//validate the fields and set the ErrorMessage
//disable the Submit button if validation fails
function formValidation( formData: IApplicationManipulation, initialApplicationData: IApplicationManipulation, setDisplayNameErrorMessage: { ( value: React.SetStateAction<boolean> ): void }, setDisplayDescriptionErrorMessage: { ( value: React.SetStateAction<boolean> ): void }, setDisableButton: { ( value: React.SetStateAction<boolean> ): void } ) {
  const stringValidations = {
    name: { min: 3, max: 20 },
    description: { min: 1, max: 100 }
  };
  const name = formData.name.trimEnd();
  const description = formData.description.trimEnd();
  const validName = name.length >= stringValidations.name.min && name.length <= stringValidations.name.max && RegExPatterns.AplhaNumericHyphenUnderscore.test( name );
  const validDescription = description.length >= stringValidations.description.min && description.length <= stringValidations.description.max;
  const detailsUpdated = JSON.stringify( initialApplicationData ) !== JSON.stringify( { ...formData, name } )
  const validChannelName = formData.channelName !== '';
  const validViews = formData.views.length > 0;

  setDisplayNameErrorMessage( !validName )
  setDisplayDescriptionErrorMessage( !validDescription )
  setDisableButton( !validName || !validDescription || !detailsUpdated || !validChannelName || !validViews )
}


export const ApplicationManipulation = ( props: IManipulationProps ) => {
  const { t } = useTranslation();
  const initialData = { 'name': '', 'description': '', 'channelName': '', 'views': [], 'isActive': true };
  const [formData, setFormData] = useState<IApplicationManipulation>( initialData );
  const [initialApplicationData, setInitialApplicationData] = useState<IApplicationManipulation>( initialData );
  const [disableButton, setDisableButton] = useState( true );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState<boolean>( false );
  const [displayDescriptionErrorMessage, setDisplayDescriptionErrorMessage] = useState<boolean>( false );
  const [displayAlertMessage, setDisplayAlertMessage] = useState<boolean>( false );

  const manipulationType = props.type; //To get the type of manipulation being performed

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state: AppState ) => state.error )
  const enumValues = useSelector( ( state: AppState ) => state.dataMaintainance.enumListOfValues );

  const channels = getEnumOptions( enumValues, ELovValues.ECHT );
  const views = getEnumOptions( enumValues, ELovValues.EVIEW );

  useEffect( () => {
    if ( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialApplicationData, setFormData, dispatch )
    }
  }, [] )

  useEffect( () => {
    handleError( error, setDisplayAlertMessage );
  }, [error] )

  useEffect( () => {
    formValidation( formData, initialApplicationData, setDisplayNameErrorMessage, setDisplayDescriptionErrorMessage, setDisableButton )
  } )

  const handleSubmit = ( event: { preventDefault: () => void; } ) => {
    event.preventDefault();
    dispatch( resetError() );
    setDisplayAlertMessage( false );
    callCreateOrUpdateApi( manipulationType, props, formData, t, dispatch )
  }


  const handleClose = ( _event?: React.SyntheticEvent | Event, reason?: string ) => {
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };

  return <Box className="manipulationBox pt-1">
    <Box className="manipulationForm">
      <Collapse in={ displayAlertMessage } className="show-alert">
        <Alert className="errorMessage" severity="error" onClose={ handleClose }>
          <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType } /> </AlertTitle>
        </Alert>
      </Collapse>

      <TextField data-testid="application-name-input" name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.name || '' } onChange={ ( e ) => {
        handleChange( e, setFormData )
      } }
      /><br />
      <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br />

      <TextField name="description" label={ t( 'labels.description' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e ) => {
        handleChange( e, setFormData )
      } }
      /><br />
      <Collapse in={ displayDescriptionErrorMessage && formData.description.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.100LimitErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>

      <fieldset className="inputFieldset w-100 mt-1em">
        <legend className="inputLegend" id="channelName">{t( 'labels.channelName' )}  <span className="asterisk">*</span></legend>
        <RadioGroup
          aria-labelledby="channelName"
          name="channelName"
          value={ formData.channelName }
          onChange={ ( event ) => {
            handleChange( event, setFormData )
          } }
        > <Grid container spacing={ 2 } className="pt-1">
            {channels.map( ( value: IEnumOption ) => {
              return <Grid item xs={ 4 } key={ value.code } className="pt-0">
                <FormControlLabel value={ value.code } label={ value.name } control={ < Radio data-testid="channel-radio" /> } disabled={ !formData.isActive } />
              </Grid>
            } )
            }
          </Grid>
        </RadioGroup>
      </fieldset>
      <fieldset className="inputFieldset w-100 mt-1em">
        <legend className="inputLegend" id="views"> {t( 'labels.views' )}  <span className="asterisk">*</span></legend>
        {
          <RadioGroup
            aria-labelledby="views"
            name="views"
            value={ formData.views }

          > <Grid container spacing={ 2 } className="pt-1">
              {views.map( ( value: IEnumOption ) => {
                return <Grid item xs={ 4 } key={ value.code } className="pt-0">
                  <FormControlLabel data-testid="view-checkbox" value={ value.code } label={ value.name } disabled={ manipulationType === EActionType.Edit && !formData.isActive }
                    control={ <Checkbox checked={ formData.views.includes( value.code ) } onChange={ ( e ) => {
                      handleChangeView( e, setFormData, formData )
                    } }
                    /> }
                  /></Grid>
              } )
              }
            </Grid>
          </RadioGroup>}
      </fieldset>

      {!initialApplicationData.isActive && manipulationType === EActionType.Edit ? <FormControlLabel
        value={ formData.isActive }
        checked={ !!formData.isActive }
        control={ <Checkbox /> }
        label={ t( 'labels.isActive' ) }
        labelPlacement="end"
        onChange={ ( e ) => {
          handleChange( e, setFormData )
        } }
        name="isActive"
        className="mt-1em"
      /> : null}
      <br />

      <Box className="formActionButtons">
        <Button data-testid="application-button-submit" variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
          {manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' )}
        </Button>
        <Button data-testid="application-button-cancel" size="medium" onClick={ () => {
          resetAllErrorInfo()
          setDisplayAlertMessage( false );
        } } className="text-capitalize"
        >{t( 'button.cancel' )} </Button>
      </Box>

    </Box>
  </Box>

}