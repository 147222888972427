import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store/AppStore';

const initialState = {
  busy: false
}

const busySlice = createSlice( {
  name: 'busy',
  initialState,
  reducers: {
    setBusy( state ) {
      state.busy = true;
    },
    setNotBusy( state ) {
      state.busy = false;
    }
  }
} ) 
  
export const { setBusy, setNotBusy } = busySlice.actions

export const busy = ( state: AppState ) => state

export default busySlice.reducer