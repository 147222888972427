import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationApi } from '../../api/ApplicationApi';

//Application thunks

//To get paginated Applications data
export const getApplications = createAsyncThunk( 'applications/getApplications', async ( action:{ token: string, page:number, limit:number, searchCode?: string} )=>{
  return ApplicationApi.getApplications( action );
} )

//To create an application
export const createApplication = createAsyncThunk( 'applications/createApiKey', async ( action:{ token: string, request } )=>{
  return ApplicationApi.createApplication( action );
} );

//To Update an application 
export const updateApplication = createAsyncThunk( 'applications/updateApiKey', async ( action:{ token: string, request } )=>{
  return ApplicationApi.updateApplication( action );
} );

//To delete an application
export const deleteApplications = createAsyncThunk( 'applications/deleteApplications', async ( action:{ token: string, request } )=>{
  return ApplicationApi.deleteApplications( action );
} );

//To get application details
export const getApplicationDetails = createAsyncThunk( 'applications/getApplicationDetails', async ( action:{ token: string, request } )=>{
  return ApplicationApi.getApplicationDetails( action );
} );
