import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { HeaderLogin } from '.';
import { useNavigate } from 'react-router';
import { EPageNames, ETabValue } from '../data/Constants';
import { IHeaderProps } from '../../types';

/**
 * Renders the header which also includes the buttons to hide/show the TabDrawer and InfoPanel
 * @param {IHeaderProps} headerProps the properties for the header component
 * @returns {JSX.Element} the header component
 */
export const Header = ( headerProps: IHeaderProps ) => {
  const navigate = useNavigate();
  const philipsLogo = '../../public/png/logo.png';
  const navigateToHome = ( )=>{
    headerProps.setTabValue( ETabValue.AdministrationTab ); // setting tabValue to administration
    navigate( EPageNames.UserManagement ,{replace:true} )
  }

  return (
    <AppBar className="headerStyle root" position="static">
      <Toolbar className="toolbar">
        <Box className="toolbarSection">
          { EPageNames.UserManagement.indexOf( location.pathname ) < 0 ?
            <img className="zoom80 headerLogo" src={ philipsLogo } onClick={ navigateToHome } /> :
            <img className="zoom80 " src={ philipsLogo } />}
        </Box>
        <Box className="toolbarSection">
          <HeaderLogin />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
