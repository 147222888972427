import { createAsyncThunk } from '@reduxjs/toolkit';
import { PriceApi } from '../../api/PriceApi';
import { PriceListMapApi } from '../../api/PriceListMapApi';

//Price thunk
//To get the paginated price data
export const getPrice = createAsyncThunk( 'price/getPrice', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  action.materialCode = action.searchCode;
  return PriceApi.getPrice( action );
} )

//To delete price
export const deletePrice = createAsyncThunk( 'price/deletePrices', async ( action:{token: string} ) => {
  return PriceApi.deletePrices( action );
} )

//PricelistMap thunks
//To get paginated price list map data
export const getPriceListMap = createAsyncThunk( 'price/getPriceListMap', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return PriceListMapApi.getPriceListMap( action );
} )

//To get a price list map details
export const getPriceListMapDetails = createAsyncThunk( 'price/getPriceListMapDetails', async ( action:{ token: string, searchCode: string } )=>{
  return PriceListMapApi.getPriceListMapDetails( action );
} )
 
//To update price list map
export const updatePriceListMap = createAsyncThunk( 'price/updatePriceListMap', async ( action:{ token: string, request} )=>{
  return PriceListMapApi.updatePriceListMap( action );
} )
  