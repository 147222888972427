import './settings';
import '../style/index.less';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { AppStore } from './store';
import { theme } from '../style/theme';
import {Buffer} from 'buffer';
import { AuthComponent } from './components';
import './i18n';//for localization
import { createRoot } from 'react-dom/client';
import cspSettings from '../settings/csp/csp.settings.json';

const container = document.getElementById( 'root' )!;
const root = createRoot( container );


const cacheValue = cspSettings.Enable ? createCache( {
  key:'nonce',
  nonce:Buffer.from( 'secretnoncephilips', 'base64' ).toString( 'base64' ) //eslint-disable-line
} ) : createCache( {key:'philips'} );
root.render(
  <CacheProvider value={ cacheValue }>
    <BrowserRouter>
      <ThemeProvider theme={ theme }>
        <Provider store={ AppStore }>
          <AuthComponent />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </CacheProvider>
  
);
