import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateMaintenanceData, updateMaintenanceSearchData } from '../../services/DataHelperFunctions';
import { FeatureFlagApi } from '../../api/FeatureFlagApi';

const initialState = {
  featureFlags: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  }
}

//Feature flag thunks
//Gets the paginated feature flags
export const getFeatureFlags = createAsyncThunk( 'featureFlags/getFeatureFlags', async ( action:{ token: string, page:number, limit:number, searchCode?: string, activeOnly?: boolean } )=>{
  return FeatureFlagApi.getFeatureFlags( action );
} );

//To create a feature flag
export const createFeatureFlag = createAsyncThunk( 'featureFlags/createFeatureFlag', async ( action:{ token: string, request } )=>{
  return FeatureFlagApi.createFeatureFlag( action );
} );

//To update a feature flag
export const updateFeatureFlag = createAsyncThunk( 'featureFlags/updateFeatureFlag', async ( action:{ token: string, request } )=>{
  return FeatureFlagApi.updateFeatureFlag( action );
} );

//To get feature flag details
export const getFeatureFlagDetails = createAsyncThunk( 'featureFlags/getFeatureFlagDetails', async ( action:{ token: string, request } )=>{
  return FeatureFlagApi.getFeatureFlagDetails( action );
} );

//To delete feature flag(s)
export const deleteFeatureFlags = createAsyncThunk( 'featureFlags/deleteFeatureFlags', async ( action:{ token: string, request } )=>{
  return FeatureFlagApi.deleteFeatureFlags( action );
} );


const featureFlagsSlice = createSlice( {
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: ( builder ) =>{
    builder.addCase( getFeatureFlags.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'featureFlags' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'featureFlags' );
        }
      }
    } )
  }
} )


export default featureFlagsSlice.reducer

