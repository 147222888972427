import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { ErrorMessage } from '../ErrorMessage';
import { getSalesText } from '../../store/states/SalesTextSlice';
import { useSelector } from 'react-redux';
import { IManagementProps } from '../../../types';
import { AppState } from '../../store/AppStore';

export const SalesText = ( props: IManagementProps ) => {
  const {t} = useTranslation();

  const error = useSelector( ( state:AppState )=> state.error )

  if( error.code && error.type === EApiType.SalesTextKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.salesText' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.SalesText }
      getDataList = { getSalesText }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}