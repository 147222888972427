import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField,Checkbox,SelectChangeEvent, FormControlLabel, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { getLanguageAssociation, deleteLanguageAssociation, getLanguageDetails, updateLanguageAssociation } from '../../../store/states/LanguageSlice'
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';

const handleUpdate = ( res,props,token,t, dispatch: AppDispatch )=>{
  if( res?.data ) {
    dispatch( getLanguageAssociation( {token: token, page: props.data.page, limit: props.data.recordsPerPage, searchCode: props.data.searchValue} ) );
    dispatch( setAlertMessage( {show:true,message:t( 'messages.success.update' ),type:'success'} ) );
    dispatch( resetDialogMessage() )
  }else{
    const message = res?.error?.message ? res.error.message : t( 'messages.fail.update' );
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }
}

export const LanguageAssociationManipulation = ( props ) => {
  const {t} = useTranslation();
  const token = props.token;
  const [formData, setFormData] = useState( {'productCode':'','languages':[]} );
  const [languagesAvailable, setLanguagesAvailable] = useState( [] );
  const [initialFormData, setInitialFormData] = useState( {} )
  const [disableButton, setDisableButton] = useState( true );

  const dispatch = useDispatch<AppDispatch>();
  
  useEffect( ()=>{
    dispatch( getLanguageDetails( {token: token, request: [] } ) ).then( response =>{ //To fetch all the available languages
      setLanguagesAvailable( response.payload.data );
    } )     
    setFormData( {productCode:props.data.productCode,languages:props.data.languages.map( p=> p.code ).sort() } );
    setInitialFormData( {productCode:props.data.productCode,languages:props.data.languages.map( p=> p.code ).sort() } );
  },[] )

  useEffect( ()=>{
    formData.languages.sort( );
    const detailsUpdated = JSON.stringify( initialFormData ) !== JSON.stringify( formData );
    setDisableButton( !detailsUpdated );
  } )

  const handleChangeCheck = ( event:SelectChangeEvent )=>{
    const tempFormData = JSON.parse( JSON.stringify( formData ) )
    if( event.target.checked ) {
      tempFormData.languages.push( event.target.value )
    }else{
      tempFormData.languages.splice( tempFormData.languages.findIndex( ( val ) => val === event.target.value ),1 )
    }
    setFormData(
      tempFormData
    )
  }

  const handleSubmit = ()=>{
    const addLang = [];
    const delLang = [];
    //find which is present in initial but not in current those are deleted
    //find which is present in current but not in inital those are added
   
    initialFormData.languages.forEach( element => {
      if( formData.languages.indexOf( element ) === -1 ) {
        delLang.push( element )
      }
    } );
    
    formData.languages.forEach( element => {
      if( initialFormData.languages.indexOf( element ) === -1 ) {
        addLang.push( element )
      }
    } );

    if( addLang.length > 0 ) {
      dispatch( updateLanguageAssociation( {token: token, request: {...formData,languages:addLang} } ) ).then( res=>{
        handleUpdate( res.payload,props,token,t, dispatch )
      } )
    }

    if( delLang.length > 0 ) {
      dispatch( deleteLanguageAssociation( {token: token,request: {...formData,languages:delLang}} ) ).then( res=>{
        handleUpdate( res.payload,props,token,t, dispatch )
      } )
    }
  }
  
  return <>
   
    <Box className="manipulationBox" style={ {'paddingTop':'4vh'} }>
      <Box className="manipulationForm" >
        <TextField name="productCode" label={ t( 'labels.productCode' ) } variant="outlined" size="small" fullWidth value={ formData.productCode } disabled/>
        <fieldset className="inputFieldset w-100 mt-1em">
          <legend className="inputLegend">{t( 'labels.languages' )} </legend>
          <br/>
          <Grid container spacing={ 5 } className="pt-1">
            { languagesAvailable.map( ( obj )=> {
              return obj.isActive ? <Grid item xs={ 4 } key={ obj.code } className="pt-0"> <FormControlLabel key={ obj.code } label={ obj.code + ' (' + obj.name + ')' } control={ < Checkbox onChange={ handleChangeCheck } checked={ formData.languages.indexOf( obj.code ) > -1 } value={ obj.code } /> }/>  </Grid> : null 
            }
            )}
          </Grid>
        </fieldset>
        <br/>
        <br/>
        <Box className="formActionButtons">
          <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { t( 'button.save' ) }
          </Button>
          <Button size="medium" onClick={ ()=>dispatch( resetDialogMessage() ) } className="text-capitalize" >{ t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}