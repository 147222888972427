import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../../data/Constants';
import { ErrorMessage } from '../../ErrorMessage';
import { getProductHierarchy } from '../../../store/states/ProductHierarchySlice';
import { useSelector } from 'react-redux';
import { IManagementProps } from '../../../../types';
import { AppState } from '../../../store/AppStore';

export const ProductHierarchy = ( props: IManagementProps ) => {
  const {t} = useTranslation();

  const error = useSelector( ( state:AppState )=> state.error )


  if( error.code && error.type === EApiType.ProductHierarchyKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.productHierarchy' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.ProductHierarchy }
      getDataList = { getProductHierarchy }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}