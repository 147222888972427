import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Collapse, Alert, AlertTitle, Autocomplete, InputAdornment } from '@mui/material';
import { AppDispatch, AppState, AppStore } from '../../../store';
import { EActionType,EAction } from '../../../data/Constants';
import { ErrorMessage } from '../../ErrorMessage';
import SearchIcon from '@mui/icons-material/Search';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import {getPriceListMap, getPriceListMapDetails, updatePriceListMap } from '../../../store/states/PriceSlice';
import { resetAlertMessage, setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetError } from '../../../store/states/ErrorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IManipulationProps } from '../../../../types';
import { resetAllErrorInfo } from '../../../services/DataHelperFunctions';


function handleApiResponse( res, props: IManipulationProps, failMessage:string, successMessage:string, dispatch:AppDispatch ) {
  const priceListMap = AppStore.getState().dataMaintainance.priceListMap;
  if( res?.data ) {
    dispatch( getPriceListMap( { token: props.token, page: priceListMap.currentPage, limit: priceListMap.recordsPerPage, searchCode: priceListMap.searchKey } ) )
    dispatch( setAlertMessage( {show:true,message:successMessage,type:'success'} ) )
    dispatch( resetDialogMessage() ); 
  }else{
    const message = res?.error?.message ? res.error.message : failMessage
    dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
  }
}

//call api to Update price list map
function callPriceListMapUpdateApi( props: IManipulationProps, formData, t:( message:string ) => string, dispatch: AppDispatch ) {
  dispatch( updatePriceListMap( {token: props.token, request: {priceMaps:[formData]} } ) ).then( res =>{
    handleApiResponse( res.payload, props, t( 'messages.fail.update' ), t( 'messages.success.update' ), dispatch );
  } )
}

const loadFormData = async ( props: IManipulationProps, setInitialPriceMapData, setFormData, setCountryList, setFilteredCountryList, dispatch:AppDispatch ) => {
  const countryList = AppStore.getState().dataMaintainance.countries.filter( c => c.isActive );
  let priceListMap = await dispatch( getPriceListMapDetails( {token: props.token, searchCode: props.id } ) ).unwrap();

  if( !priceListMap || !priceListMap.data?.priceMaps[0] ) {
    return;
  }

  priceListMap = priceListMap.data.priceMaps[0];
  setCountryList( countryList );
  setFilteredCountryList( countryList );
  setInitialPriceMapData( priceListMap );
  setFormData( priceListMap );
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;
  const value = event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const handleSearch = ( props ) =>{
  const { countryList, searchVal, setFilteredCountryList } = props;
  const filteredData = countryList.filter( country => country.name.toLowerCase().includes( searchVal.toLowerCase() ) || country.code.toLowerCase().includes( searchVal.toLowerCase() ) )
  setFilteredCountryList( filteredData )
}

const SearchBar = ( props )=>{
  const { countryList, searchValue, filteredCountryList, setSearchValue, setFilteredCountryList } = props
  return <div className="priceListMapSearchBar">
    <Autocomplete
      id="country-search-box"
      clearOnBlur
      freeSolo
      value = { searchValue }
      options={ [] }
      className="search-bar"
      renderInput={ ( params ) => <TextField { ...params } className="search-inner-text" placeholder={ 'Search' } InputProps={ { ...params.InputProps,
        startAdornment:  <InputAdornment position="end"> <SearchIcon />
        </InputAdornment>
      } }
             
      /> }
      onChange={ ( _,newVal )=>{
        const searchVal = newVal ? newVal : '';
        setSearchValue( searchVal );
        handleSearch( { countryList, searchVal, setFilteredCountryList } );
      } }
    />
    {searchValue ? <div className="records">{'Records: ' + filteredCountryList.length}</div> : null}
  </div>
}

export const PriceListMapManipulation = ( props: IManipulationProps ) => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState( {'country':'','map':''} );
  const [initialPriceMapData, setInitialPriceMapData] = useState( {'country':'','map':''} );
  const [searchValue, setSearchValue] = useState( '' );
  const [countryList, setCountryList] = useState( [] );
  const [filteredCountryList, setFilteredCountryList] = useState( [] );
  const [disableButton, setDisableButton] = useState( true );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const manipulationType = props.type; //To get the type of manipulation being performed

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )
 
 
  useEffect( ()=>{
    const detailsUpdated = JSON.stringify( initialPriceMapData ) !== JSON.stringify( formData )
    setDisableButton( !detailsUpdated )
  } )
  
  useEffect( ()=>{
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialPriceMapData, setFormData, setCountryList, setFilteredCountryList, dispatch )
    }
  },[] )

  useEffect( () => {     
    if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
      setDisplayAlertMessage( true );
    }    
  }, [error] ) 

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };

  const handleSubmit = ( event ) => {
    event.preventDefault();
    dispatch( resetError() ); 
    setDisplayAlertMessage( false );
    callPriceListMapUpdateApi( props, formData, t, dispatch )
  }

  const handleCancel = ( ) =>{
    resetAllErrorInfo()
    setDisplayAlertMessage( false ); 
  }

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
          </Alert>
        </Collapse>
        <TextField name="country" label={ t( 'labels.country' ) } disabled variant="outlined" size="small" fullWidth value={ formData.country }/>
        <br/><br/>
        <TextField name="map" label={ t( 'labels.map' ) } disabled variant="outlined" size="small" fullWidth value={ initialPriceMapData.map }/>
        <br/><br/>
        <fieldset className="inputFieldset w-100">
          <legend className="inputLegend" id="map">{t( 'labels.newMap' ) }</legend>
          {SearchBar( { countryList, searchValue, filteredCountryList, setSearchValue, setFilteredCountryList } )}
          {countryList.length < 1 ? <p>{ t( 'messages.noCountriesFound' )}</p> :
            <RadioGroup
              aria-labelledby="map"
              name="map"
              value={ formData.map } 
              onChange={ ( e ) => {
                handleChange( e, setFormData )
              } }
            > <Grid container spacing={ 2 } className="pt-1">
                {filteredCountryList.map( ( value:string ) => {
                  return <Grid item xs={ 4 } key={ value.code } className="pt-0"> <FormControlLabel value={ value.code } label={ value.code + ' (' + value.name + ')' } control={ < Radio /> }/></Grid>
                } )
                }
              </Grid>
            </RadioGroup>}
        </fieldset>
        <Box className="formActionButtons">
          <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { t( 'button.save' ) }
          </Button>
          <Button size="medium" onClick={ handleCancel } className="text-capitalize"> { t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}