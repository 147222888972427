import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { ErrorMessage } from '../ErrorMessage';
import { getProductCatalog } from '../../store/states/DataMaintenanceSlice';
import { IManagementProps } from '../../../types';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/AppStore';

export const ProductCatalog = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const error = useSelector( ( state:AppState )=> state.error )


  if( error.code && error.type === EApiType.ProductCatalogKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.productCatalog' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.ProductCatalog }
      getDataList = { getProductCatalog }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}