import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import TermsAndCondition from './TermsAndCondition';
import { appSettings } from '../settings';
import { useTranslation } from 'react-i18next';
import { EEnvironments } from '../data/Constants';

export const Footer = () => {
  const { t } = useTranslation();
  const color: { [index: string]: string } = {
    DEV: 'bg-yellow',
    TEST: 'bg-orange',
    ACC: 'bg-green',
    Prod: 'bg-gray'
  };
  const [value, setValue] = React.useState('');
  const getVersion = () => {
    return fetch('../public/versioninfo.json', { cache: 'no-store' }).then((response) =>
      response.json()
    );

  };
  useEffect(() => {
    getVersion().then((data) => {
      setValue(Array.isArray(data) ? data[0].Version : data?.Version);
    });
  }, []);

  if (appSettings.Environment === EEnvironments.PROD) {
    return null;
  }

  return (
    <Grid className={`footer root ${color[appSettings.Environment]}`} >
      <Grid className="env">
        <span >{t('footer.env')}: {appSettings.Environment}</span>
      </Grid>
      <Grid className="termsAndConditionStyle">
        <TermsAndCondition />
        &nbsp;&nbsp;
        <span >{t('footer.version')}:{value}</span>
      </Grid>
    </Grid>
  );
}

