import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { IManagementProps } from '../../../types';
import { ErrorMessage } from '../ErrorMessage';
import {getPrice, deletePrice } from '../../store/states/PriceSlice';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, AppDispatch, AppStore } from '../../store/AppStore';
import { setAlertMessage } from '../../store/states/AlertMessageSlice';

export const Prices = ( props:IManagementProps ) => {
  const {t} = useTranslation();

  const error = useSelector( ( state:AppState )=> state.error )
  const dispatch = useDispatch<AppDispatch>();
  
  const deletePrices = () => {
    const price = AppStore.getState().dataMaintainance.prices;
    dispatch( deletePrice( {token: props.token} ) ).then( res => {
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.prices' )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getPrice( {token: props.token, page: price.currentPage, limit: price.recordsPerPage, searchCode: price.searchKey} ) )
      } else{
        const message = t( 'messages.fail.delete.prices' );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }
  
  if( error.code && error.type === EApiType.PriceKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }
  
  return <>
    <h3 className="mainHeader"> {t( 'header.price' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.Prices }
      getDataList = { getPrice }
      moduleType = { EModuleType.Datamaintainance }
      deleteItems={ deletePrices }
    />
  </>
}