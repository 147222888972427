import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EApiType, EDataTableType, EModuleType, TableHeaders } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { ErrorMessage } from '../../ErrorMessage';
import { getAllProperties, getPropertyAssociations } from '../../../store/states/PropertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { IManagementProps } from '../../../../types';
import { AppDispatch, AppState } from '../../../store/AppStore';
import { setColumnsData } from '../../../services/DataHelperFunctions';

export const PropertyAssociation = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )
  const propertyList = useSelector( ( state:AppState )=> state.dataMaintainance.propertyList )
  const [columns,setColumns] = useState( [] )

  if( error.code && error.type === EApiType.PropertyAssociationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  useEffect( ()=>{
    dispatch( getAllProperties( {token: props.token} ) );
  },[] )

  useEffect( ()=>{
    setColumnsData( propertyList,setColumns,TableHeaders[EDataTableType.PropertyAssociation].main );
  },[propertyList] )


  return <><h3 className="mainHeader"> {t( 'header.propertyAssociation' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.PropertyAssociation }
      getDataList = { getPropertyAssociations }
      moduleType = { EModuleType.Datamaintainance }
      columns={ columns }
    /></>
}