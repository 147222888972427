import { createSlice } from '@reduxjs/toolkit';
import { updateMaintenanceData, updateMaintenanceSearchData } from '../../services/DataHelperFunctions';
import { AppState } from '../AppStore';
import { getRoles } from './RoleSlice';
import { getApiKeys } from './ApiKeySlice';
import { getApplications } from './ApplicationSlice';
import { getUsers } from './UserSlice';

const initialState = {
  data: [],
  users: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  roles: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  apikeys: {
    data:{},
    totalCount:0,
    currentPage:0,
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  applications: {
    data:{},
    totalCount:0,
    currentPage:0,
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  }
}

const administrationSlice = createSlice( {
  name: 'administration',
  initialState,
  reducers: {},
  extraReducers: ( builder ) =>{
    builder.addCase( getUsers.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'users' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'users' );
        }
      } 
    } );
    builder.addCase( getRoles.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'roles' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'roles' );
        }
      }
    } );
    builder.addCase( getApiKeys.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'apikeys' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'apikeys' );
        }
      }
    } );
    builder.addCase( getApplications.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'applications' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'applications' );
        }
      }
    } );
  }
} ) 
  

export const administration = ( state:AppState ) => state.administration

export default administrationSlice.reducer