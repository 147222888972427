import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: []
}

const technicalInfoSlice = createSlice( {
  name: 'technicalInfo',
  initialState,
  reducers: {
    setTechnicalInfoMsg( state, action ) {
      state.messages.push( action.payload.message );
    }
  }
} )

export const { setTechnicalInfoMsg } = technicalInfoSlice.actions;

export default technicalInfoSlice.reducer