import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthState } from '../store/states/UserSlice';
import { AppState } from '../store/AppStore';

/**
 * Renders an error message for unauthorized users
 * @returns {JSX.Element} the error message component
 */
export const Unauthenticated = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const authState = useSelector( ( state:AppState ) => state.user.authState )

  const [showMessage,setShowMessage] = useState( false );
  const [timer,setTimer] = useState<NodeJS.Timer>()
  useEffect( ()=>{
    if( authState !== 'logout' ) {
      showMessage && setShowMessage( false );
      setTimer( setTimeout( ()=>setShowMessage( true ),5000 ) );
    }else{
      !showMessage && setShowMessage( true );
    }
    return ()=>{
      timer && clearTimeout( timer );
      dispatch( setAuthState( 'initial' ) )
    }
  },[authState] )
  return <>
    { showMessage && <h4>
      {t( 'messages.unauthenticated' )}
    </h4> || null }
  </>;
};