import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField,Checkbox,SelectChangeEvent, FormControlLabel, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { getMarketAssociation, deleteMarketAssociation, getMarketDetails, updateMarketAssociation } from '../../../store/states/MarketSlice';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';

const handleUpdate = ( res,props,token,t, dispatch: AppDispatch )=>{
  if( res?.data ) {
    dispatch( getMarketAssociation( { token: token, page: props.data.page, limit: props.data.recordsPerPage, searchCode: props.data.searchValue} ) );
    dispatch( setAlertMessage( {show:true,message:t( 'messages.success.update' ),type:'success'} ) )
    dispatch( resetDialogMessage() );
  }else{
    const message = res?.error?.message ? res.error.message : t( 'messages.fail.update' )
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }
}

export const MarketAssociationManipulation = ( props ) => {
  const {t} = useTranslation();
  const token = props.token;
  const [formData, setFormData] = useState( {'productCode':'','markets':[]} );
  const [marketsAvailable, setMarketsAvailable] = useState( [] );
  const [initialFormData,setInitialFormData] = useState( {} )
  const [disableButton,setDisableButton] = useState( true );

  const dispatch = useDispatch<AppDispatch>();
  
  useEffect( ()=>{
    dispatch( getMarketDetails( {token: token, request: []} ) ).then( response => {
      setMarketsAvailable( response.payload.data );
    } )
    setFormData( {productCode:props.data.productCode,markets:props.data.markets.map( p=> p.code ).sort() } );
    setInitialFormData( {productCode:props.data.productCode,markets:props.data.markets.map( p=> p.code ).sort() } );
  },[] )

  useEffect( ()=>{
    formData.markets.sort( )
    const detailsUpdated = JSON.stringify( initialFormData ) !== JSON.stringify( formData );
    setDisableButton( !detailsUpdated )
  } )

  const handleChangeCheck = ( event:SelectChangeEvent )=>{
    const tempFormData = JSON.parse( JSON.stringify( formData ) )
    if( event.target.checked ) {
      tempFormData.markets.push( event.target.value )
    }else{
      tempFormData.markets.splice( tempFormData.markets.findIndex( ( val ) => val === event.target.value ),1 )
    }
    setFormData(
      tempFormData
    )
  }

  const handleSubmit = ()=>{
    const addMark = [];
    const delMark = [];
    //find which is present in initial but not in current those are deleted
    //find which is present in current but not in inital those are added
   
    initialFormData.markets.forEach( element => {
      if( formData.markets.indexOf( element ) === -1 ) {
        delMark.push( element )
      }
    } );
    
    formData.markets.forEach( element => {
      if( initialFormData.markets.indexOf( element ) === -1 ) {
        addMark.push( element )
      }
    } );

    if( addMark.length > 0 ) {
      dispatch( updateMarketAssociation( { token: token, request: { ...formData, markets:addMark }} ) ).then( res=>{
        handleUpdate( res.payload, props, token, t, dispatch )
      } )
    }
    
    if( delMark.length > 0 ) {
      dispatch( deleteMarketAssociation( {token: token, request: { ...formData, markets:delMark } } ) ).then( res=>{
        handleUpdate( res.payload, props, token, t, dispatch )
      } )
    }   
  }

  return <>
    <Box className="manipulationBox" style={ {'paddingTop':'4vh'} }>
      <Box className="manipulationForm" >
        <TextField name="productCode" label={ t( 'labels.productCode' ) } variant="outlined" size="small" fullWidth value={ formData.productCode } disabled/>
        <fieldset className="inputFieldset w-100 mt-1em">
          <legend className="inputLegend">{t( 'labels.markets' )} </legend>
          <br/>
          <Grid container spacing={ 5 } className="pt-1">
            { marketsAvailable?.map( ( obj )=> {
              return obj.isActive ? <Grid item xs={ 4 } key={ obj.code } className="pt-0"> <FormControlLabel key={ obj.code } label={ '' + obj.code + ' (' + obj.name + ')' } control={ < Checkbox onChange={ handleChangeCheck } checked={ formData.markets.indexOf( obj.code ) > -1 } value={ obj.code } /> }/>  </Grid> : null 
            }
            )}
          </Grid>
        </fieldset>
        <br/>
        <br/>
        <Box className="formActionButtons">
          <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { t( 'button.save' ) }
          </Button>
          <Button size="medium" onClick={ ()=> dispatch( resetDialogMessage() ) } className="text-capitalize" >{ t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}