import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, AppState } from '../../../store';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { getUsers, deleteUsers } from '../../../store/states/UserSlice';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';


export const UserManagement = ( props:IManagementProps ) => {
  
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error );
  const users = useSelector( ( state:AppState )=> state.administration.users );

  if( error.code && error.type === EApiType.UserKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }


  const deleteItems = ( req )=>{
    
    dispatch( deleteUsers( {token: props.token, request : req } ) ).then( res=>{
      const list = joinSelectedKeys( req )
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.users', { length:req.length, list: list } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getUsers( {token: props.token, page: users.currentPage, limit: users.recordsPerPage, searchCode: users.searchKey} ) );
      }else{
        const message = t( 'messages.fail.delete.users',{ length:req.length, list: list} );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.userManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Users }
      getDataList = { getUsers }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}