import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationRequest } from '../../../types/IApiTypes';
import { NotificationApi } from '../../api/NotificationApi';
import { updateMaintenanceData, updateMaintenanceSearchData } from '../../services/DataHelperFunctions';

const initialState = {
  ace: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  }
}


export const getNotifications = createAsyncThunk( 'notification/getNotifications', async ( action: IPaginationRequest )=>{
  return NotificationApi.getNotifications( action );
} );

const notificationSlice = createSlice( {
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: ( builder ) =>{
    builder.addCase( getNotifications.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'ace' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'ace' );
        }
      }
    } )
  }
} )

export const notification = ( state ) => state

export default notificationSlice.reducer