import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../../data/Constants';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { getMarketAssociation } from '../../../store/states/MarketSlice';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/AppStore';

export const MarketAssociation = ( props:IManagementProps ) => {
  const {t} = useTranslation();

  const error = useSelector( ( state:AppState )=> state.error )

  if( error.code && error.type === EApiType.MarketAssociationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.marketAssociation' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.MarketAssociation }
      getDataList = { getMarketAssociation }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}