import { createSlice } from '@reduxjs/toolkit';
import { IAlertMessage } from '../../../types';

const initialState:IAlertMessage = {
  show:false,
  message:'',
  type:''
}

const alertMessageSlice = createSlice( {
  name: 'alertMessage',
  initialState,
  reducers: {
    setAlertMessage( state, action ) {
      return action.payload; 
    },
    resetAlertMessage() {
      return initialState; 
    },
  },
} )

export const { setAlertMessage,resetAlertMessage } = alertMessageSlice.actions

export default alertMessageSlice.reducer