import { ComparePHLegend, EDataMaintenanceType, EModuleType, EActivity, EApiType} from '../../../data/Constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { Box, Button} from '@mui/material';
import {ColorLegend} from '../../ColorLegend';
import { IProductHierarchyProps } from '../../../../types';
import React from 'react';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { resetCompareProductHierarchy } from '../../../store/states/DataMaintenanceSlice';
import { compareProductHierarchy, publishProductHierarchy } from '../../../store/states/ProductHierarchySlice';
import { ErrorMessage } from '../../ErrorMessage';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetError } from '../../../store/states/ErrorSlice';

//Publish or Discard actions
const callPublishAPI = ( activity: EActivity, token: string, dispatch: AppDispatch, t: ( msg: string )=> string ) =>{
  dispatch( publishProductHierarchy( {token: token,activity: activity} ) ).then( ( res:any ) => {
    if( !res?.payload || res.payload.error ) { 
      let errorMessage = t( 'messages.fail.discard' );
      if( activity === EActivity.Publish ) {
        errorMessage = t( 'messages.fail.publish' )
      }   
      dispatch( setAlertMessage( {show:true,message:res?.payload?.error.message ? res.payload.error.message : errorMessage ,type:'error'} ) );
    }else{
      dispatch( resetCompareProductHierarchy() );
      dispatch( setAlertMessage( {show:true,message: activity === EActivity.Publish ? t( 'messages.success.publish' ) : t( 'messages.success.discard' ),type:'success'} ) );
    }
  } )
  dispatch( resetDialogMessage() );
};

/**
 * Product hierarchy Compare component
 * @param props 
 * @returns Returns the Product hierarchy Compare data table
 */

export const ProductHierarchyCompare = ( props: IProductHierarchyProps ) => {
  
  const {t} = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const comparePHData = useSelector( ( state:AppState )=> state.dataMaintainance.compareProductHierarchy );
  const error = useSelector( ( state:AppState )=> state.error )

  const handleDisableButton = ()=>{
    return !comparePHData.data[1] || comparePHData.data[1].length <= 0;
  }

  const handleCloseCompare = ()=>{
    dispatch( resetDialogMessage() );
    dispatch( resetError() );
  }

  
  if( error.code && error.type === EApiType.CompareProductHierarchyKey ) {
    return <>
      <h4>
        <ErrorMessage error={ error } />
      </h4>
      <Box className="formActionButtons">
        <Button size="medium" className="text-capitalize" onClick = { ()=>handleCloseCompare() }>{t( 'button.ok' )}</Button>
      </Box>
    </>
  }

  return <>
    <br/>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.CompareProductHierarchy }
      getDataList = { compareProductHierarchy }
      moduleType = { EModuleType.Datamaintainance }
    /> 
    <Box className="formActionButtons"> 
      {comparePHData.data[1]?.length > 0 && 
        <ColorLegend legendItems = { ComparePHLegend } /> } 
      <Button variant="contained" size="medium" disabled={ handleDisableButton() } className="text-capitalize" onClick={ ()=>callPublishAPI( EActivity.Publish, props.token, dispatch, t ) }>{t( 'button.publish' )}</Button>
      <Button variant="contained" size="medium" disabled={ handleDisableButton() } className="text-capitalize" onClick={ ()=>callPublishAPI( EActivity.Discard, props.token, dispatch, t ) }>{t( 'button.discard' )}</Button>
      <Button size="medium" className="text-capitalize" onClick = { ()=>handleCloseCompare() }>{t( 'button.cancel' )}</Button>
    </Box> 
  </>
}