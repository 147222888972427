import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatDateString } from '../../services/DataHelperFunctions';
import { ECellType, TableHeaders } from '../../data/Constants';

export const DetailsDialog = ( objectData, type, t, displayName?: boolean ) => {

  const tableHeaders = TableHeaders[type].details;

  return <TableContainer component={ Paper } className="detailsTable">
    <Table stickyHeader aria-label="Information table">
      <TableHead>
        <TableRow key="DetailedInformationHeader">
          {tableHeaders.map( header => {
            return <TableCell key={ header.field } style={ { width: header.width} }>{t( 'labels.' + header.field )}</TableCell>
          } )}
        </TableRow>
      </TableHead>
      <TableBody>
        {objectData?.length > 0 ? 
          objectData.map( ( data, index: number ) => {
            return <TableRow key = { index }>
              {tableHeaders.map( header => {
                switch( header.cellType ) {
                  case ECellType.Text: 
                    return <TableCell key={ header.field } style={ { wordBreak: 'break-word'} }>{ data[header.key] } { displayName && ' (' + data.name + ')' }</TableCell>
                  case ECellType.Link: 
                    return <TableCell key={ header.field } style={ { wordBreak: 'break-word'} }><a href={ data[header.key] } target="_blank" rel="noreferrer">{ data[header.key] }</a> { displayName && ' (' + data.name + ')' }</TableCell>
                  case ECellType.Date: 
                    return <TableCell key={ header.field }>{ formatDateString( data[header.field] ) }</TableCell>
                  default:
                    return <TableCell key={ header.field }>{ data[header.field] }</TableCell>
                }
              } )}
            </TableRow>
          } ) : <div>{t( 'messages.noDataFound' )}</div>}
      </TableBody>
    </Table>
  </TableContainer>
}