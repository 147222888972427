import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EAction } from '../../data/Constants';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DraggableComponent from '../DraggableComponent';
import { AppDispatch, AppState } from '../../store/AppStore';
import { resetAlertMessage } from '../../store/states/AlertMessageSlice';


export const AlertMessage = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState ) => state.error )
  const alertMessage = useSelector( ( state:AppState ) => state.alertMessage )


  if( !alertMessage.type ) {
    return null;
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage( ) );
  };

  const AlertMessageDialogContent = ( {message, type} ) => {
    const icon = type === 'error' ? <CancelOutlinedIcon color={ type }/> : <CheckCircleOutlinedIcon color={ type }/>;
    return <div className="alertDialogContents">
      <span className="alertIcon">{icon}</span>
      <span>{message}</span>
    </div>
  }


  if( error.code && ( error.action === EAction.Update || error.action === EAction.Create ) ) {
    return <></>
  }

  return <Dialog open={ alertMessage.show } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title" className="alert-dialog common-dialog-style cursor-move">
    <DialogTitle className="header title">
      {t( 'dialogBox.' + alertMessage.type )}
    </DialogTitle>
    <DialogContent sx={ {paddingTop:'1em'} }>
      <AlertMessageDialogContent message={ alertMessage.message } type={ alertMessage.type }/>
    </DialogContent>
    <DialogActions>
      <Button onClick={ handleClose }>
        {t( 'button.ok' )}
      </Button>
    </DialogActions>
  </Dialog>
}