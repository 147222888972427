import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField,Checkbox,FormControlLabel, Collapse, Alert, AlertTitle } from '@mui/material';
import { AppDispatch, AppState, AppStore } from '../../../store';
import { EActionType,EAction, RegExPatterns } from '../../../data/Constants';
import { IError, IManipulationProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { resetAlertMessage, setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { getRoles, updateRole, createRole, getRoleDetails } from '../../../store/states/RoleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetError } from '../../../store/states/ErrorSlice';
import { resetAllErrorInfo } from '../../../services/DataHelperFunctions';

//To call Create and Update apis
function callCreateOrUpdateApi( manipulationType:string,props:IManipulationProps,formData,t:( message:string ) => string , dispatch:AppDispatch ) {
  if( manipulationType === EActionType.Edit ) {
    dispatch( updateRole( {token: props.token,request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()} } ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.update' ),t( 'messages.success.update' ), dispatch );
    } )
  }else{
    dispatch( createRole( {token: props.token,request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()}} ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.create' ),t( 'messages.success.create' ), dispatch );
    } )
  }
}

function handleApiResponse( res, props:IManipulationProps, failMessage:string, successMessage:string, dispatch:AppDispatch ) {
  const roles = AppStore.getState().administration.roles;
  if( res?.data ) {
    dispatch( getRoles( {token: props.token,page: roles.currentPage,limit: roles.recordsPerPage,searchCode: roles.searchKey} ) )
    dispatch( setAlertMessage( {show:true,message:successMessage,type:'success'} ) )
    dispatch( resetDialogMessage() )
  }else{
    const message = res?.error?.message ? res.error.message : failMessage
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }
}

const loadFormData = async ( props, setInitialRoleData, setFormData, dispatch: AppDispatch ) => {
  let roleDetails = await dispatch( getRoleDetails( {token: props.token, request: [props.id]} ) ).unwrap();
  
  if( !roleDetails || !roleDetails.data ) {
    return;
  }
  roleDetails = roleDetails.data[0];
  setInitialRoleData( roleDetails );
  setFormData( roleDetails );
}

const handleError = ( error:IError, setDisplayAlertMessage ) => {
  if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }  
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;    
  const value = name === 'isDefault' || name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

export const RoleManipulation = ( props:IManipulationProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error );
  const [formData, setFormData] = useState( {'name':'', 'description':'', 'isDefault':false, 'isActive':true} );
  const [initialRoleData, setInitialRoleData] = useState( {'name':'', 'description':'', 'isDefault':false, 'isActive':true} );
  const [disableButton, setDisableButton] = useState( true );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState( false );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const stringValidations = {
    name: {min: 3}
  }

  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialRoleData, setFormData, dispatch )
    }
  },[] )

  useEffect( () => {
    handleError( error, setDisplayAlertMessage );
  }, [error] ) 

  useEffect( ()=>{
    const name = formData.name.trimEnd();
    const validName = name.length >= stringValidations.name.min && RegExPatterns.AlphaNumericUnderscore.test( name );
    const detailsUpdated = JSON.stringify( initialRoleData ) !== JSON.stringify( {...formData,name } )
    setDisplayNameErrorMessage( !validName )
    setDisableButton( !validName || !detailsUpdated )
  } )

  const handleSubmit = ( event ) => {
    event.preventDefault();
    dispatch( resetError() );
    setDisplayAlertMessage( false );
    callCreateOrUpdateApi( manipulationType,props,formData,t, dispatch )
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
          </Alert>
        </Collapse>
        <TextField data-testid="role-name-input" name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.name || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } }
        /><br/>
        <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
        <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e )=>{
          handleChange( e,setFormData )
        } }
        /><br/>
        <br/>
     
        <FormControlLabel
          value={ formData.isDefault }
          checked={ !!formData.isDefault }
          control={ <Checkbox /> }
          label={ t( 'labels.isDefault' ) }
          labelPlacement="end"
          disabled={ manipulationType === EActionType.Edit && !formData.isActive }
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isDefault"
        />

        {!initialRoleData.isActive && manipulationType === EActionType.Edit ? <FormControlLabel
          value={ formData.isActive }
          checked={ !!formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isActive' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isActive"
        /> : null}        
        <br/>

        <Box className="formActionButtons">
          <Button data-testid="role-button-submit" variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
          </Button>
          <Button size="medium" data-testid="role-button-cancel" onClick={ ()=> {
            resetAllErrorInfo()
            setDisplayAlertMessage( false ); 
          } } className="text-capitalize"
          >{ t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}