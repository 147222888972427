import { createAsyncThunk } from '@reduxjs/toolkit';
import { LanguageApi } from '../../api/LanguageApi';


//Language Thunks
//Gets paginated language data 
export const getLanguages = createAsyncThunk( 'language/getLanguages', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return LanguageApi.getLanguages( action );
} )

//To get a language details
export const getLanguageDetails = createAsyncThunk( 'language/getLanguageDetails', async ( action:{ token: string, request: string[] } )=>{
  return LanguageApi.getLanguageDetails( action );
} )
  
//To update a language
export const updateLanguage = createAsyncThunk( 'language/updateLanguage', async ( action:{ token: string, request } )=>{
  return LanguageApi.updateLanguage( action );
} )
  
//To create a language
export const createLanguage = createAsyncThunk( 'language/createLanguage', async ( action:{ token: string, request }, )=>{
  return LanguageApi.createLanguage( action );
} )

//To delete the language(s)
export const deleteLanguage = createAsyncThunk( 'language/deleteLanguage', async ( action:{ token: string, request } )=>{
  return LanguageApi.deleteLanguage( action );
} )
  
//Language Association Thunks
//To get paginated language association data
export const getLanguageAssociation = createAsyncThunk( 'language/getLanguageAssociation', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  action.productId = action.searchCode;
  return LanguageApi.getLanguageAssociation( action );
} )

//To update language association data
export const updateLanguageAssociation = createAsyncThunk( 'language/updateLanguageAssociation', async ( action:{ token: string, request } )=>{
  return LanguageApi.updateLanguageAssociation( action );
} )
 
//To delete language association data
export const deleteLanguageAssociation = createAsyncThunk( 'language/deleteLanguageAssociation', async ( action:{ token: string, request } )=>{
  return LanguageApi.deleteLanguageAssociation( action );
} )
  