import React, {useEffect}from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../../data/Constants';
import { ErrorMessage } from '../../ErrorMessage';
import { getCountryList} from '../../../store/states/MarketSlice';
import { getPriceListMap } from '../../../store/states/PriceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { IManagementProps } from '../../../../types';

export const PriceListMap = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state: AppState )=> state.error )


  useEffect( ()=>{
    dispatch( getCountryList( {token: props.token} ) )
  },[] )

  if( error.code && error.type === EApiType.PriceListMapKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.priceListMap' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.PriceListMap }
      getDataList = { getPriceListMap }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}