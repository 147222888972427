import { createSlice } from '@reduxjs/toolkit';
import { IApplicationSettings } from '../../../types/ITypes';

const initialState:IApplicationSettings = {
  showSectionTabs: true,
  forceShowSectionTabs: false,
  pageSize: {
    isExtraSmall: false,
    isSmall: false, 
    isMedium: false,
    isLarge: false,
    isExtraLarge: false
  }
} 

const applicationSettingsSlice = createSlice( {
  name: 'applicationSettings',
  initialState,
  reducers: {
    setApplicationSettings( _, action ) {
      return action.payload; 
    },
    setShowSectionTabs( state, action ) {
      state.showSectionTabs = action.payload;
    },
    setForceShowSectionTabs( state, action ) {
      state.forceShowSectionTabs = action.payload;
    },
    setPageSize( state, action ) {
      state.pageSize = action.payload;
    },
    resetApplicationSettings() {
      return initialState;
    },
  },
} )

export const { setApplicationSettings,setShowSectionTabs,setForceShowSectionTabs,setPageSize, resetApplicationSettings } = applicationSettingsSlice.actions

export const selectApplicationSettings = ( state: IApplicationSettings ) => state

export default applicationSettingsSlice.reducer