import React from 'react';
import { UserPanel } from './UserPanel';
import { useAuth } from 'oidc-react';
import { Button } from '@mui/material';
import { authSettings } from '../api/OidcSettings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAuthState } from '../store/states/UserSlice';
import { AppDispatch } from 'store';


/**
 * Renders a button for login and logout functionality
 * @returns {JSX.Element} the user menu component
 */
export const HeaderLogin = () => {
  if ( authSettings.Developer_Mode ) {
    return <Button color="secondary">{'LOCAL DEVELOPER'}</Button>;
  }
  const {t} = useTranslation();
  const auth = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedin = auth && auth.userData;
  const userName = `${auth.userData?.profile.name}`;

  return isLoggedin
    ? <UserPanel user={ userName } onLogout={ ()=>{
      dispatch( setAuthState( 'logout' ) )
      auth.signOut();
    } }
    />
    : <Button color="secondary" onClick={ () => {
      dispatch( setAuthState( 'login' ) )
      auth.signIn();
    } }
    >{t( 'button.login' )}</Button>;
};