import { ClickAwayListener, Drawer, PaperProps, Tab, Tabs, IconButton } from '@mui/material';
import React,{ useEffect, useState} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isChildOf } from '../services';
import { styleConstants, administrationTabs, dataMaintenanceTabs, fearureFlagTabs, productTabs, notificationTabs } from '../data/Constants';
import { ITabDrawerProps,IApplicationSettings, ISectionTab } from '../../types';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/material/styles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import WebIcon from '@mui/icons-material/Web';
import FlagIcon from '@mui/icons-material/Flag';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HandymanIcon from '@mui/icons-material/Handyman';
import { setForceShowSectionTabs, setShowSectionTabs } from '../store/states/ApplicationSettingsSlice';
import { AppState } from '../store/AppStore';

const getSXPaperProps = ( applicationSettings:IApplicationSettings )=>{
  return {
    position: applicationSettings.pageSize.isSmall ? 'absolute' : 'initial',
    transition: '.15s',
    boxShadow: applicationSettings.pageSize.isSmall ? '8px 0 8px -8px black' : 'none',
    top: applicationSettings.pageSize.isExtraSmall ? styleConstants.extraSmallHeaderHeight : styleConstants.headerHeight,
    bottom: '0',
    height: applicationSettings.pageSize.isSmall ? 'unset' : '100%'
  }
}

function showTab( applicationSettings:IApplicationSettings ) {
  return !applicationSettings.pageSize.isSmall && applicationSettings.showSectionTabs || applicationSettings.pageSize.isSmall && applicationSettings.forceShowSectionTabs;
}

function getIcon( index,tabValue ) {
  if( tabValue === 'administration' ) {
    switch ( index ) {
      case 1:
        return <PersonIcon/>
      case 2:
        return <ManageAccountsIcon/>
      case 3:
        return <KeyIcon/>
      case 4:
        return <WebIcon/>
    }
  }else if( tabValue === 'dataMaintenance' ) {
    switch ( index ) {
      case 1:
        return <NotesOutlinedIcon/>
      case 2:
        return <ImageOutlinedIcon/>
      case 3:
        return <AccountTreeOutlinedIcon/>
      case 4:
        return <AttachMoneyIcon/>
      case 5:
        return <RequestQuoteIcon/>
      case 6:
        return <HandymanIcon/>
    }
  } else if( tabValue === 'product' ) {
    switch ( index ) {
      case 1:
        return <StorefrontIcon/>
      case 2:
        return <AddBusinessIcon/>
      case 3:
        return <TranslateIcon/>
      case 4:
        return <LanguageIcon/>
      case 5:
        return <CategoryIcon/>
      case 6:
        return <PermDataSettingIcon/>
      case 7:
        return <PostAddIcon/>
      case 8:
        return <DynamicFeedIcon/>     
      default:
        return <TranslateIcon/>
    }
  } else if( tabValue === 'featureFlags' ) {
    if( index === 1 ) {
      return <FlagIcon/>
    }
  } else if( tabValue === 'notification' ) {
    return <NotificationsIcon/>
  }
}

/**
 * Renders the section tabs on the left side on the home screen.
 * Gets automatically hidden on smaller screens
 * @param {ITabDrawerProps} props the properties for the tab drawer component
 * @returns {JSX.Element} the tab drawer component
 */
export const TabDrawer = ( { tabValue }:ITabDrawerProps ) => {
  
  const dispatch = useDispatch();
  const applicationSettings = useSelector( ( state:AppState )=> state.applicationSettings );
  const [activeTab, setActiveTab] = useState( 0 );

  if( !applicationSettings || !tabValue ) {
    return null;
  }

  let sections: ISectionTab[];
  switch( tabValue ) {
    case 'administration':
      sections = administrationTabs;
      break;
    case 'dataMaintenance':
      sections = dataMaintenanceTabs;
      break;
    case 'product':
      sections = productTabs;
      break;
    case 'featureFlags':
      sections = fearureFlagTabs;
      break;
    case 'notification':
      sections = notificationTabs;
      break;
  }
  const navigate = useNavigate();

  const onTabChange = ( _: React.SyntheticEvent<Element, Event>, value : number ) =>{
    navigate( sections[value].path ); 
    setActiveTab( value );
  };

  useEffect( () => {
    const path = window.location.pathname.split( '/' )[1];
    const index = path ? sections.findIndex( s=>s.path === '/' + path ) : 0;
    setActiveTab( index > 0 ? index : 0 ) 
  },[window.location] );

 
  const onClickAway = ( e: MouseEvent | TouchEvent ) => {
    
    if( !isChildOf( e.target as HTMLElement, 'tabDrawerToggle' ) && 
  applicationSettings.forceShowSectionTabs ) {
      dispatch( setForceShowSectionTabs( false ) )
    }

  }

  const show = showTab( applicationSettings );
  const paperProps: Partial<PaperProps<'div', unknown>> = {
    sx: getSXPaperProps( applicationSettings ),
    className: classNames( 'w-0', { ['drawerOpen']: show } )
  };
  let tabIndex = 0;
  let minitabIndex = 0;
  
  const tabs = sections?.map( s => 
    <Tab key={ `${s.id}-${tabIndex}` } id={ s.id } label={ <span>{s.name}</span> }
      value={ tabIndex++ } className="tab-drawer-tabs" iconPosition="start"
      icon={ getIcon( s.id,tabValue ) }
    /> )
  const minitabs = sections?.map( s => 
    <Tab key={ `${s.id}-${minitabIndex}` } id={ s.id } 
      value={ minitabIndex++ } className="tab-drawer-tabs" 
      icon={ getIcon( s.id,tabValue ) }
    /> )

  const DrawerHeader = styled( 'div' )( ( ) => ( {
    display: 'flex',
    alignItems: 'center'
  } ) );

  const onToggleDrawer = () => {
    if ( applicationSettings.pageSize.isSmall ) {
      dispatch( setForceShowSectionTabs( !applicationSettings.forceShowSectionTabs ) )
      
    } else {
      dispatch( setShowSectionTabs( !applicationSettings.showSectionTabs ) )
      
    }
  };

  return <>{!show ? <div className="tabDrawerMini">
    <IconButton id="tabDrawerToggle" onClick={ onToggleDrawer } className="toggleIcon">  <KeyboardDoubleArrowRightIcon className={ 'pinIcon' }/></IconButton>

    <Tabs value={ activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar className="tab-toggle">
      { minitabs }
    </Tabs>
  
  </div> :
    <>
      <ClickAwayListener onClickAway={ onClickAway }>
        <Drawer variant="permanent" open PaperProps={ paperProps } className="h-100">
          <DrawerHeader>
            <IconButton onClick={ onToggleDrawer } className="left-arrow">
              <KeyboardDoubleArrowLeftIcon className={ 'pinIcon' }/>
            </IconButton>
          </DrawerHeader>
          <Tabs value={ activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar className="tab-toggle">
            { tabs }
          </Tabs>
        </Drawer>
      </ClickAwayListener>
    </>
  }
  </>
}