import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../store';
import { IManagementProps } from '../../../types';
import { ErrorMessage } from '../ErrorMessage';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../data/Constants';
import { joinSelectedKeys } from '../../services/DataHelperFunctions';
import { deleteFeatureFlags, getFeatureFlags } from '../../store/states/FeatureFlagSlice';
import { setAlertMessage } from '../../store/states/AlertMessageSlice';


export const FeatureFlagsManagement = ( props: IManagementProps ) => {
  
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )

  const deleteItems = ( req )=>{
    const featureFlags = AppStore.getState().featureFlags.featureFlags;
    dispatch( deleteFeatureFlags( {token: props.token, request: req } ) ).then( res=>{
      const delKeys = joinSelectedKeys( req )
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.featureFlags', { length:req.length, list:delKeys} )
        dispatch( setAlertMessage( {show:true, message: message , type:'success'} ) )
        dispatch( getFeatureFlags( {token: props.token, page: featureFlags.currentPage, limit: featureFlags.recordsPerPage, searchCode: featureFlags.searchKey, activeOnly: false } ) );
      }else{
        const message = t( 'messages.fail.delete.featureFlags', { length:req.length, list: delKeys} );
        dispatch( setAlertMessage( {show:true ,message:message, type:'error'} ) )
      }
    } )
  }
  
  if( error.code && error.type === EApiType.FeatureFlagsKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.featureFlagsManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.FeatureFlags }
      getDataList = { getFeatureFlags }
      moduleType = { EModuleType.FeatureFlags }
      deleteItems={ deleteItems }
    />
  </>
}