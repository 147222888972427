import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show:false,
  message:null,
  type:'',
  manipulationType:'',
  header:null,
  detailsViewType:'',
  uniquekey:'',
  moduleType:''
}

const dialogSlice = createSlice( {
  name: 'dialog',
  initialState,
  reducers: {
    setDialogMessage( state,action ) {
      return action.payload;
    },
    resetDialogMessage() {
      return initialState;
    }
  }
} ) 
  
export const { setDialogMessage, resetDialogMessage } = dialogSlice.actions

export const dialog = ( state ) => state

export default dialogSlice.reducer