import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { getApiKeys, deleteApiKeys, getPrivileges, getConfigitPropertyList, getPdmPropertyList, getServiceUsers } from '../../../store/states/ApiKeySlice';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';

export const ApiKeyManagement = ( props:IManagementProps ) => {

  const {t} = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )

  useEffect( () => {
    dispatch( getServiceUsers( {token: props.token} ) );
    dispatch( getConfigitPropertyList( {token: props.token} ) );
    dispatch( getPdmPropertyList( {token: props.token} ) );
    dispatch( getPrivileges( {token: props.token} ) );
  },[] )

  if( error.code && error.type === EApiType.ApiKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  const deleteItems = ( req:string[],keysSelected: string[] )=>{
    const apikeys = AppStore.getState().administration.apikeys;
    dispatch( deleteApiKeys( { token: props.token, request: {'ids':req} } ) ).then( res=>{
      const delKeys = joinSelectedKeys( keysSelected );
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.apikeys', { length:req.length, list:delKeys } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getApiKeys( {token: props.token, page: apikeys.currentPage, limit: apikeys.recordsPerPage, searchCode: apikeys.searchKey } ) );
      }else{
        const message = t( 'messages.fail.delete.apikeys', { length:req.length, list: delKeys} );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }
 
  return <>
    <h3 className="mainHeader"> {t( 'labels.apiKeyManagement' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.ApiKeys }
      getDataList = { getApiKeys }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}